import React from 'react';
import { FaParking, FaRestroom, FaWifi } from "react-icons/fa";
import { TbAirConditioning } from "react-icons/tb";
import { PiCoffeeFill } from "react-icons/pi";
import { MdBatteryChargingFull } from "react-icons/md";

const Home = () => {
  return (
    <div className="flex flex-col items-center"
    style={{
           background: 'rgb(247, 227, 210)', // Light orange color
           backgroundImage: 'linear-gradient(0deg, rgba(255,204,153,1) 0%, rgba(255,255,255,1) 100%)' // Gradient from light orange to light yellow
         }}
    >
      {/* Hero Section */}
      <div className="w-full h-screen bg-cover bg-center relative" 
           style={{ backgroundImage: "url('https://sawsandbeans.com/wp-content/uploads/2024/02/DSC05454-1-scaled.jpg')" }}>
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="text-center px-4 sm:px-8 md:px-12 lg:px-24">
            <div className="rounded-xl bg-white bg-opacity-70 p-6 sm:p-8 md:p-12 lg:p-16">
              <h1 className="text-amber-800 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold">GetCabin Space</h1>
              <p className="font-medium text-base sm:text-lg md:text-xl lg:text-2xl mt-4">Reserve Your Workspace or Meeting Room</p>
              <p className="font-medium text-base sm:text-lg md:text-xl lg:text-2xl">Collaborate Better, Together</p>
            </div>
          </div>
        </div>
      </div>

      {/* Office Solutions Section */}
      <section className="w-full py-12 sm:py-16 text-center">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold">Our Unique Office Solutions</h2>
        <div className="flex flex-wrap justify-center mt-8 gap-4">
          {/* Placeholder for office solutions */}
          <div className="w-24 h-24 bg-[#FFEFE6] shadow-md md:w-32 md:h-32"></div>
          <div className="w-24 h-24 bg-[#FFEFE6] shadow-md md:w-32 md:h-32"></div>
          <div className="w-24 h-24 bg-[#FFEFE6] shadow-md md:w-32 md:h-32"></div>
          <div className="w-24 h-24 bg-[#FFEFE6] shadow-md md:w-32 md:h-32"></div>
        </div>
      </section>

      {/* Amenities Section */}
      <section className="w-full py-12 sm:py-16 text-center">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold">Amenities</h2>
        <div className="icons rounded-2xl p-6 sm:p-8 md:p-12 lg:p-16 shadow-2xl bg-white grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-6 sm:gap-8 mt-8 mx-4 sm:mx-8 md:mx-16 lg:mx-32">
          {/* FIRST ROW */}
          <div className="flex flex-col items-center">
            <span className="text-3xl sm:text-4xl " title='Wifi'><FaWifi /></span>
            <p className="text-base sm:text-lg md:text-xl mt-2">Wifi</p>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-3xl sm:text-4xl " title='Air Conditioning'><TbAirConditioning /></span>
            <p className="text-base sm:text-lg md:text-xl mt-2">Air Conditioning</p>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-3xl sm:text-4xl " title='Coffee and Tea'><PiCoffeeFill /></span>
            <p className="text-base sm:text-lg md:text-xl mt-2">Coffee and Tea</p>
          </div>
          {/* SECOND ROW */}
          <div className="flex flex-col items-center">
            <span className="text-3xl sm:text-4xl " title='Restroom'><FaRestroom /></span>
            <p className="text-base sm:text-lg md:text-xl mt-2">Restroom</p>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-3xl sm:text-4xl" title='Power Backup'><MdBatteryChargingFull /></span>
            <p className="text-base sm:text-lg md:text-xl mt-2">Power Backup</p>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-3xl sm:text-4xl" title='Parking'><FaParking /></span>
            <p className="text-base sm:text-lg md:text-xl mt-2">Parking</p>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="w-full py-12 sm:py-16 text-center">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold">What Is GetCabinSpace</h2>
        <p className="mt-4 sm:mt-6 mx-4 sm:mx-8 md:mx-16 text-base sm:text-lg md:text-xl text-justify">
          Welcome to GetCabin Space, your premier destination for booking meeting rooms. Whether you’re attending or conducting meetings, our platform offers a seamless experience to find and reserve the perfect cabin space. Built with React and Tailwind CSS, our application ensures intuitive navigation and responsive design across devices. Enjoy real-time availability updates, calendar integrations, and secure user authentication for streamlined booking management. Join us in enhancing your meeting experiences with efficiency and convenience. GetCabin Space: where every meeting finds its room.
        </p>
      </section>
    </div>
  );
}

export default Home;
